import React, { Suspense, useLayoutEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

// Home
const Home = React.lazy(() => import("./components/pages/Home"));
const Hometwo = React.lazy(() => import("./components/pages/Hometwo"));
// Blog
const Blog = React.lazy(() => import("./components/pages/Blog"));
const Blogstandard = React.lazy(() =>
  import("./components/pages/Blogstandard")
);
const Blogdetails = React.lazy(() => import("./components/pages/Blogdetails"));
// About
const About = React.lazy(() => import("./components/pages/About"));
// Services
const Services = React.lazy(() => import("./components/pages/Services"));
const Servicedetails = React.lazy(() =>
  import("./components/pages/Servicedetails")
);
// FAQ's
const Faqs = React.lazy(() => import("./components/pages/Faqs"));
// Appointment
const Appointment = React.lazy(() => import("./components/pages/Appointment"));
// Request
const Request = React.lazy(() => import("./components/pages/Request"));
// Clinics
const Clinicgrid = React.lazy(() => import("./components/pages/Clinicgrid"));
const Cliniclist = React.lazy(() => import("./components/pages/Cliniclist"));
const Clinicdetails = React.lazy(() =>
  import("./components/pages/Clinicdetails")
);
// Doctors
const Doctorgrid = React.lazy(() => import("./components/pages/Doctorgrid"));
const Doctorlist = React.lazy(() => import("./components/pages/Doctorlist"));
const Doctordetails = React.lazy(() =>
  import("./components/pages/Doctordetails")
);
// Contact
const Contact = React.lazy(() => import("./components/pages/Contact"));
// Partner
const Partners = React.lazy(() => import("./components/pages/Partners"));
// Extra
const Errorpage = React.lazy(() => import("./components/pages/Errorpage"));
// Case Study
const CaseStudyPage = React.lazy(() => import("./components/pages/CaseStudy"));
const Casedetails = React.lazy(() => import("./components/pages/Casedetails"));

//News
const News = React.lazy(() => import("./components/pages/News"));
const Newsdetails = React.lazy(() => import("./components/pages/Newsdetails"));

//Article
const Articles = React.lazy(() => import("./components/pages/Articles"));
const Articlesdetails = React.lazy(() =>
  import("./components/pages/Articlesdetails")
);

// Scroll to Top
const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
});

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <ScrollToTop>
          <Switch>
            {/* Home */}
            <Route exact path="/" component={Home} />
            {/* <Route exact path="/home-v2" component={Hometwo} /> */}
            {/* Blog */}
            {/* <Route
              exact
              path="/blog/cat/:catId"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* <Route
              exact
              path="/blog/tag/:tagId"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* <Route
              exact
              path="/blog/search/:query"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* <Route
              exact
              path="/blog/author/:authorId"
              component={(props) => (
                <Blog {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* <Route exact path="/blog" component={Blog} />
            <Route exact path="/blog-standard" component={Blogstandard} />
            <Route
              exact
              path="/blog-details/:id"
              component={(props) => (
                <Blogdetails {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* About */}
            <Route exact path="/about" component={About} />
            {/* Services */}
            {/* <Route
              exact
              path="/service/cat/:catId"
              component={(props) => (
                <Services {...props} key={window.location.pathname} />
              )}
            /> */}

            {/* <Route exact path="/services" component={Services} /> */}
            <Route
              exact
              path="/service-details/:id"
              component={(props) => (
                <Servicedetails {...props} key={window.location.pathname} />
              )}
            />
            {/* FAQ's */}
            {/* <Route exact path="/faqs" component={Faqs} /> */}
            {/* Appointment */}
            {/* <Route exact path="/appointment" component={Appointment} /> */}
            {/* Request */}
            <Route exact path="/request" component={Request} />
            {/* Clinics */}
            {/* <Route
              exact
              path="/clinic/cat/:catId"
              component={(props) => (
                <Clinicgrid {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* <Route exact path="/clinic-grid" component={Clinicgrid} />
            <Route exact path="/clinic-list" component={Cliniclist} />
            <Route
              exact
              path="/clinic-details/:id"
              component={(props) => (
                <Clinicdetails {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* Doctors */}
            {/* <Route
              exact
              path="/doctor/cat/:catId"
              component={(props) => (
                <Doctorgrid {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/doctor-grid" component={Doctorgrid} />
            <Route exact path="/doctor-list" component={Doctorlist} />
            <Route
              exact
              path="/doctor-details/:id"
              component={(props) => (
                <Doctordetails {...props} key={window.location.pathname} />
              )}
            /> */}
            {/* Contact */}
            {/*Case Study*/}
            <Route exact path="/cases" component={CaseStudyPage} />
            <Route
              exact
              path="/cases/:id"
              component={(props) => (
                <Casedetails {...props} key={window.location.pathname} />
              )}
            />

            {/* Partner*/}
            <Route exact path="/partners" component={Partners} />
            {/* Contact*/}
            <Route exact path="/contact" component={Contact} />
            {/*Library*/}
            <Route exact path="/news" component={News} />
            <Route
              exact
              path="/news/:id"
              component={(props) => (
                <Newsdetails {...props} key={window.location.pathname} />
              )}
            />
            <Route exact path="/articles" component={Articles} />
            <Route
              exact
              path="/articles/:id"
              component={(props) => (
                <Articlesdetails {...props} key={window.location.pathname} />
              )}
            />
            {/* Extra */}
            <Route exact path="/error-page" component={Errorpage} />
            <Route exact component={Errorpage} />
          </Switch>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;

/* 

  [
    {
        "id":1,
        "image":"assets/img/home-1/slider.png",
        "subtitle":"Markets & Resources",
        "title":"See More, Diagnose Confidently!"
    },
    {
        "id":2,
        "image":"assets/img/home-1/slider-1.png",
        "subtitle":"Markets & Resources",
        "title":"Make The Best Surgical Planning Decisions!"
    },
    {
        "id":3,
        "image":"assets/img/home-1/slider-2.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":4,
        "image":"assets/img/home-1/model-img/1.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":5,
        "image":"assets/img/home-1/model-img/2.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":6,
        "image":"assets/img/home-1/model-img/3.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":7,
        "image":"assets/img/home-1/model-img/4.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":8,
        "image":"assets/img/home-1/model-img/17.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":9,
        "image":"assets/img/home-1/model-img/22.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":10,
        "image":"assets/img/home-1/model-img/10.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":11,
        "image":"assets/img/home-1/model-img/24.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":12,
        "image":"assets/img/home-1/model-img/23.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":11,
        "image":"assets/img/home-1/model-img/22.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":11,
        "image":"assets/img/home-1/model-img/21.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":11,
        "image":"assets/img/home-1/model-img/20.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":11,
        "image":"assets/img/home-1/model-img/19.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    },
    {
        "id":11,
        "image":"assets/img/home-1/model-img/18.png",
        "subtitle":"Markets & Resources",
        "title":"For A More Personalized Care Journey!"
    }
]

*/
